exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-components-checkout-form-jsx": () => import("./../../../src/pages/components/checkoutForm.jsx" /* webpackChunkName: "component---src-pages-components-checkout-form-jsx" */),
  "component---src-pages-components-contact-jsx": () => import("./../../../src/pages/components/contact.jsx" /* webpackChunkName: "component---src-pages-components-contact-jsx" */),
  "component---src-pages-components-footer-main-jsx": () => import("./../../../src/pages/components/footerMain.jsx" /* webpackChunkName: "component---src-pages-components-footer-main-jsx" */),
  "component---src-pages-components-home-main-jsx": () => import("./../../../src/pages/components/homeMain.jsx" /* webpackChunkName: "component---src-pages-components-home-main-jsx" */),
  "component---src-pages-components-image-modal-jsx": () => import("./../../../src/pages/components/ImageModal.jsx" /* webpackChunkName: "component---src-pages-components-image-modal-jsx" */),
  "component---src-pages-components-menu-jsx": () => import("./../../../src/pages/components/menu.jsx" /* webpackChunkName: "component---src-pages-components-menu-jsx" */),
  "component---src-pages-components-nostros-jsx": () => import("./../../../src/pages/components/nostros.jsx" /* webpackChunkName: "component---src-pages-components-nostros-jsx" */),
  "component---src-pages-components-pago-con-jsx": () => import("./../../../src/pages/components/pagoCon.jsx" /* webpackChunkName: "component---src-pages-components-pago-con-jsx" */),
  "component---src-pages-components-pagos-jsx": () => import("./../../../src/pages/components/pagos.jsx" /* webpackChunkName: "component---src-pages-components-pagos-jsx" */),
  "component---src-pages-components-par-agen-jsx": () => import("./../../../src/pages/components/parAgen.jsx" /* webpackChunkName: "component---src-pages-components-par-agen-jsx" */),
  "component---src-pages-components-par-taiw-jsx": () => import("./../../../src/pages/components/parTaiw.jsx" /* webpackChunkName: "component---src-pages-components-par-taiw-jsx" */),
  "component---src-pages-components-par-usadas-jsx": () => import("./../../../src/pages/components/parUsadas.jsx" /* webpackChunkName: "component---src-pages-components-par-usadas-jsx" */),
  "component---src-pages-components-partes-jsx": () => import("./../../../src/pages/components/partes.jsx" /* webpackChunkName: "component---src-pages-components-partes-jsx" */),
  "component---src-pages-components-sliders-first-jsx": () => import("./../../../src/pages/components/slidersFirst.jsx" /* webpackChunkName: "component---src-pages-components-sliders-first-jsx" */),
  "component---src-pages-components-sliders-two-jsx": () => import("./../../../src/pages/components/slidersTwo.jsx" /* webpackChunkName: "component---src-pages-components-sliders-two-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

